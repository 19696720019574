import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { EventTypes, Events } from '../../global.d';

import TypedEmitter from './typedEmitter';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter() as unknown as TypedEmitter<Events>;
//export const eventManager = new PIXI.utils.EventEmitter() as TypedEmitter<Events>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as unknown as any).eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
}

//ReelBase
export const GameViewLayoutBase = {
  top: 301,
  left: 331,
};

export const PachiDropViewLayoutBase = {
  top: 0,
  left: 688,
};

//from adbe XDLayout
export const GameViewLayout = {
  ReelBase: {
    top: 301 - GameViewLayoutBase.top,
    left: 331 - GameViewLayoutBase.left,
    width: 1316,
    height: 594,
  },
  ReelFrame: {
    top: 329 - GameViewLayoutBase.top,
    left: 354 - GameViewLayoutBase.left,
    width: 1333,
    height: 608,
  },
  Title: {
    top: 0 - GameViewLayoutBase.top,
    left: 588 - GameViewLayoutBase.left,
    width: 874,
    height: 601,
  },
  JackPot: {
    Bg: {
      top: 38,
      left: 1,
      width: 452,
      height: 341,
    },
    TextGrand: {
      top: 86,
      left: 101,
      width: 248,
      height: 73,
    },
    TextMega: {
      top: 214,
      left: 98,
      width: 186,
      height: 60,
    },
    TextMajor: {
      top: 319,
      left: 87,
      width: 159,
      height: 45,
    },
  },
  Jackpot_Portrait: {
    top: 96 - 413 - 50,
    left: 45 - 27,
  },
  Slot: {
    Left: {
      top: 513 - GameViewLayoutBase.top,
      left: 538 - GameViewLayoutBase.left,
      width: 250,
      height: 250,
    },
    Center: {
      top: 513 - GameViewLayoutBase.top,
      left: 902 - GameViewLayoutBase.left,
      width: 250,
      height: 250,
    },
    Right: {
      top: 513 - GameViewLayoutBase.top,
      left: 1266 - GameViewLayoutBase.left,
      width: 250,
      height: 250,
    },
  },
  PachiDrop: {
    ReelBase: {
      top: 0,
      left: 688,
      width: 526,
      height: 238,
    },
    BoardBase: {
      top: 256,
      left: 0,
      width: 1920,
      height: 824,
    },
    Prize: {
      p1: {
        top: 911,
        left: 297,
        width: 157,
        height: 102,
      },
      p2: {
        top: 911,
        left: 444,
        width: 157,
        height: 102,
      },
    },
    Coin: {
      top: 85,
      left: 771,
      width: 96,
      height: 96,
    },
    Coin2: {
      top: 85,
      left: 916,
      width: 97,
      height: 96,
    },
  },
  wheel: {
    top: 1350, //kari
    left: 0,
    width: 1056,
    height: 664,
  },
  Portrait: {
    ReelBase: {
      top: 413,
      left: 27,
      width: 987,
      height: 448,
    },
    Jackpot: {
      top: 96,
      left: 45,
      width: 386,
      height: 249,
    },
    Title: {
      top: 143,
      left: 519,
      width: 491,
      height: 323,
    },
  },
};
//PACHI DROP
export const REELBASE_SCALE = GameViewLayout.ReelBase.width / GameViewLayout.PachiDrop.ReelBase.width;

export const DURATION_BASE_TO_PACHIDROP = 2000;
export const DURATION_PACHIDROP_TO_BASE = 1000;

export type GameViewState = 'Base' | 'ToPachiDrop' | 'PachiDrop' | 'ToBase';

// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
export const WIN_ANIMATION_INTERVAL = 1_000;
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREE_SPIN = 1_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.03;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;
export const BIG_WIN_END_DURATION = 1000;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// JACKPOT MINOR WIN SETTINGS
export const MINOR_WIN_TITLE = 'MINOR JACKPOT!';
export const MINOR_WIN_AMOUNT_LIMIT = 500;
export const MINOR_WIN_TITLE_SCALE = 1.3;
export const MINOR_WIN_ZOOM_TITLE_DURATION = 600;
export const MINOR_WIN_COUNT_UP_MULTIPLIER = 0.66;

// JACKPOT MAJOR WIN SETTINGS
export const MAJOR_WIN_TITLE = 'MAJOR JACKPOT!';
export const MAJOR_WIN_AMOUNT_LIMIT = 1000;
export const MAJOR_WIN_TITLE_SCALE = 1.3;
export const MAJOR_WIN_ZOOM_TITLE_DURATION = 600;
export const MAJOR_WIN_COUNT_UP_MULTIPLIER = 1.33; // 1000 / 60 / 12.5s

// JACKPOT GRAND WIN SETTINGS
export const GRAND_WIN_TITLE = 'GRAND JACKPOT';
export const GRAND_WIN_AMOUNT_LIMIT = 3000;
export const GRAND_WIN_TITLE_SCALE = 1.3;
export const GRAND_WIN_ZOOM_TITLE_DURATION = 600;
export const GRAND_WIN_COUNT_UP_MULTIPLIER = 4;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const WIN_SLOT_TINT_COLOR = 0x888888;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 3;
export const SLOTS_PER_REEL_AMOUNT = 1;
export const REEL_WIDTH = 364;
export const SLOT_WIDTH = 250;
export const SLOT_HEIGHT = 180;
export const SLOT_SCALE = 1.0;
export const REEL_MARGIN_LEFT = 206;
export const SHOW_ALL_LINES_ON_WIN = true;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT * 2;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 50;
export const GAME_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;
//export const SLOTS_BACKGROUND_WIDTH = 1315;
//export const SLOTS_BACKGROUND_HEIGHT = 597;
//export const SLOTS_REELFRAME_WIDTH = 1331;
//export const SLOTS_REELFRAME_HEIGHT = 603;
// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.A];
export const ANTICIPATION_START_SYMBOLS_AMOUNT = [2];
export const ANTICIPATION_END_SYMBOLS_AMOUNT = [];

export const ANTICIPATION_DELAY = 0;
export const ANTICIPATION_DURATION = 4000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 10;
export const ANTICIPATION_REEL_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);

export const ANTICIPATION_SLOTS_TINT = 0x7f7f7f;

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 0;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;
export const SAFE_AREA_LANDSCAPE_WIDTH = 1920; //1600; 1324 + 328 + 328
export const SAFE_AREA_LANDSCAPE_HEIGHT = 1080; //601 + 300 + 300
export const SAFE_AREA_LANDSCAPE_PIVOT_X = -328;
export const SAFE_AREA_LANDSCAPE_PIVOT_Y = -300;
export const SAFE_AREA_PORTRAIT_WIDTH = 1380;
export const SAFE_AREA_PORTRAIT_HEIGHT = 2200; //601 + 435 * 2
export const SAFE_AREA_PORTRAIT_PIVOT_X = -10;
export const SAFE_AREA_PORTRAIT_PIVOT_Y = -550;

export const FLEXIBLE_GAMEVIEW_POSY_RATIO = 0.5;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
// export const PERSISTENT_SHARP_SYMBOLS_ID = [SlotId.SC1, SlotId.WL, SlotId.MS1];
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// SPIN ANIMATION SETTINGS
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_FIRST_ROLLING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 600;
export const BASE_REEL_ROLLING_SPEED = 0.0155;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 12_000;
export const TURBO_REEL_STARTING_DURATION = 125;
export const TURBO_REEL_FIRST_ROLLING_DURATION = 125;
export const TURBO_REEL_ROLLING_DURATION = 100;
export const TURBO_REEL_ROLLING_SPEED = 0.05;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_STARTING_DURATION) / 3.4;
export const REEL_ENDING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_ENDING_DURATION) / 2; // (speed*duration) / (df/dt)(0)
export const BASE_REEL_STARTING_FORMULA = (t: number): number => t * t * (1.4 * t - 0.4);
export const BASE_REEL_ENDING_FORMULA = (t: number): number => 1 - (1 - t) * (1 - t);
// (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = REEL_WIDTH;
export const TINT_CONTAINER_HEIGHT = GameViewLayout.ReelBase.height;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.3;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH * 1.5;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.75;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// FREE SPINS
export const FREE_SPINS_FADE_OUT_DURATION = 1000;
export const FREE_SPINS_FADE_IN_DURATION = 1000;
export const FREE_SPINS_TIME_OUT_BANNER = 3500;

export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

export const BASEGAME_TO_FREESPIN_REEL_POSITIONS = [0, 0, 0];
export const FREEGAME_TO_BASEGAME_REEL_POSITIONS = [3, 0, 5];

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
  MinorWin,
  MajorWin,
  GrandWin,
}
// WIN TEXT SETTINGS
export const WINLABEL_POS_X = GameViewLayout.ReelBase.width / 2;
export const WINLABEL_POS_Y = 530;
export const WINLABEL_JACKPOT_POS_Y = 150;
export const WINCOUNTUP_TEXT_POS_X = GameViewLayout.ReelBase.width / 2;
export const WINCOUNTUP_LANDSCAPE_TEXT_POS_Y = SLOTS_CONTAINER_HEIGHT + 290;
export const WINCOUNTUP_PORTRAIT_TEXT_POS_Y = SLOTS_CONTAINER_HEIGHT + 620;

export type WinType = 'Normal' | 'PachiDrop' | 'Wheel';

export const winMessageCallbuck: Record<WinType, () => void> = {
  Normal: () => {
    eventManager.emit(EventTypes.COUNT_UP_END);
  },
  PachiDrop: () => {
    eventManager.emit(EventTypes.PACHIDROP_WIN_COUNT_END);
  },
  Wheel: () => {
    eventManager.emit(EventTypes.PACHIDROP_WHEEL_WIN_COUNT_END);
  },
};

//JINGLE to WIN SETTING
export const WIN_ANIM_START_DELAY = 500;
export const JINGLE_TO_WIN_DURATION = 1000;

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  10: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  15: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  16: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  17: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  18: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  19: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
};

// LINE NUMBERS CONTAINER SETTINGS
export const LINES_NUMBER_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 5;
export const RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 0;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS = [
  { id: 0, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 1, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 35 },
  { id: 2, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 70 },
  { id: 3, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 105 },
  { id: 4, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 140 },
  { id: 5, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 175 },
  { id: 6, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 210 },
  { id: 7, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 245 },
  { id: 8, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 280 },
  { id: 9, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 315 },
  { id: 10, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 350 },
  { id: 11, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 385 },
];
export const RIGHT_LINES_NUMBERS_CONTAINER = [
  { id: 12, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 13, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 32 },
  { id: 14, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 64 },
  { id: 15, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 96 },
  { id: 16, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 128 },
  { id: 17, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 160 },
  { id: 18, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 192 },
  { id: 19, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 224 },
  { id: 20, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 256 },
  { id: 21, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 288 },
  { id: 22, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 320 },
  { id: 23, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 352 },
  { id: 24, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 384 },
];
