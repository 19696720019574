import { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { SlotId } from './config';
import type { ReelSetType } from './gql/query';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState } from './slotMachine/config';
import type { IWinLine, Icon, LineSet } from './slotMachine/d';
import { PachiDropPrizeType } from './slotMachine/pachiDrop/config';
import { MoneyWheelType } from './slotMachine/pachiDrop/moneyWheel/config';

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IBalance {
  amount: number;
  currency: string;
  metadata?: IMetadata;
}

export interface IMetadata {
  type: string;
  data: {
    messageTitle: string;
    messageBody: string;
  };
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: IBalance;
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export type PachiDropResult = {
  reelID: number;
  route: PachiDropRoute[];
  position: number;
  prize: PachiDropPrizeType;
  winMulti: number;
};

export type WheelResult = {
  type: MoneyWheelType;
  position: number;
  prize: MoneyWheelSilverType | MoneyWheelGoldType;
};

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    // slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    // userBonusId: string;
    userBonus: {
      betId: string;
      //   id: string;
      //   bonusId: string;
      //   lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      bonuses: UserBonus[];
      features: {
        base: string[];
        pachiDrop: {
          position: number;
          reward: string;
          symbol: string;
          value: number;
        }[];
        wheel: {
          reward: number;
          value: number;
          wheel: string;
        };
        GW: number;
        SW: number;
      };
    };
  };
  paylines: IWinLine[];
  balance: {
    placed: IBalance;
    settled: IBalance;
  };
  rewards: BetReward[];
}

export type BetRewardType = 'BetBonusReward' | 'BetCoinReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BonusType = 'FREE_SPIN' | 'SPECIAL_ROUND' | 'GAME_MODE' | 'FREE_BET_ROUND' | '';
export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  isFreeBet?: boolean;
};
export type BonusRoundData = {
  remainingRounds: number;
  landingDiamonds: { position: number; score: number }[];
};
export type BonusData = {
  legend: BonusRoundData[];
  multipliers: {
    rows: [number, number, number];
    columns: [number, number, number, number, number];
  };
  rewardAmount: number;
  frbReferenceId: string | null;
};
export type UserBonus = {
  id: string;
  bonusId: string;
  coinValue: number;
  coinAmount: number;
  data: BonusData;
  externalUserId: string;
  bonus: Bonus;
  betId: string;
  status: BonusStatus;
  rounds: number;
  roundsPlayed: number;
  lineSetId: string;
  reelSetId: string;
  totalWinAmount: number;
  currentRound: number;
  totalRounds: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  balance?: { amount: number; currency: string };
  isFreeBet?: boolean;
};

export type FreeRoundBonus = {
  id: string;
  bonusId: string;
  status: BonusStatus;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  //roundsPlayed: number;
  totalWinAmount: number;
  isActive: boolean;
  currentRound: number;
};

export type Bonus = {
  id: string;
  slotId: string;
  reelSetId: string;
  type: BonusType;
  title?: string;
  description?: string;
  lineSetId: string | null;
  coinAmount: number;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};
export type PachiDropBonus = {
  id: string;
  coinValue: number;
  coinAmount: number;
  lineSetId: string;
  totalWinAmount: number;
  currentRound: number;
  totalRounds: number;
};
export type WheelBonus = {
  id: string;
  bonusId: string;
  lineSetId: string;
  coinValue: number;
  coinAmount: number;
  totalWinAmount: number;
  rounds: number;
  betId: string;
};
export type WheelBonuses = {
  bonus: WheelBonus[];
  currentRound: number;
  totalRounds: number;
};

export enum GameMode {
  REGULAR,
  PACHIDROP,
  SILVER_WHEEL,
  GOLD_WHEEL,
  FS_PACHIDROP,
  FREE_SPINS,
  BUY_FEATURE,
}

export enum BonusKind {
  FREE_SPINS,
  WHEEL_SILVER,
  WHEEL_GOLD,
  FS_WHEEL_SILVER,
  FS_WHEEL_GOLD,
  BUY_FEATURE,
}

export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '24d93cb1-52dc-47fd-9a98-5829335afd07',
  [GameMode.FREE_SPINS]: 'b4aceb11-b288-4d0a-8660-1dca8562df65',
  [GameMode.BUY_FEATURE]: '57b37892-5c2b-44ac-94bf-5c669b224087',
  [GameMode.SILVER_WHEEL]: '6bc7053c-cc50-4803-a4b7-c0f0f279c9f0',
  [GameMode.GOLD_WHEEL]: '9783f7cd-39e4-4272-b96c-7f3ea02555ab',
};

export const bonusIds: Record<BonusKind, string> = {
  [BonusKind.FREE_SPINS]: 'b37820d6-6993-45f8-b481-7920c3e848d3',
  [BonusKind.WHEEL_SILVER]: '067712f4-6c50-4bba-a983-7e688e89c379',
  [BonusKind.WHEEL_GOLD]: '3d292ff4-e88f-4c20-88d5-6a2510545b68',
  [BonusKind.FS_WHEEL_SILVER]: '51e12ee4-71c5-4263-8511-cdb274ec525d',
  [BonusKind.FS_WHEEL_GOLD]: '73cc0b6f-8a55-4f6e-acf9-53552dd4c587',
  [BonusKind.BUY_FEATURE]: '4cb0b700-7cb3-49b0-bf05-038ca12858c7',
};

export const freeRoundBonusId = '86af258d-d1c2-4145-8fb7-45075b30dd12';

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '1cf80be1-b436-4379-b98e-6c433ad3a422',
  [GameMode.FREE_SPINS]: '1cf80be1-b436-4379-b98e-6c433ad3a422',
  [GameMode.BUY_FEATURE]: '1cf80be1-b436-4379-b98e-6c433ad3a422',
};
export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageFreeSpinsBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  totalWin: string;
  totalWinAmount: number;
};

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  JINGLE_START = 'jingleStart',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REEL_SC_STOPPED = 'reelScStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_RETRIGGER_ANIMATION = 'startRetrigger',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_FADE = 'startFade',
  START_FS_WIN_ANIMATION = 'startFSWinAnimation',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SET_MINOR_WIN_VISIBILITY = 'setMinorWinVisibility',
  SET_MAJOR_WIN_VISIBILITY = 'setMajorWinVisibility',
  SET_GRAND_WIN_VISIBILITY = 'setGrandWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  HANDLE_DESTROY_OPENING_SCREEN = 'handleDestroyOpeningScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  BUY_FEATURE_CONFIRMED = 'buyFeatureConfirmed',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  SET_UI_BUTTON_VISIBLE = 'setUIButtonVisible',
  // FS
  END_FREESPINS = 'endFreeSpins',
  FREESPINS_PACHIDROP_DROP_END = 'freeSpinsPzchiDropDropEnd',
  SET_FREESPINS_VIEW = 'setFreeSpinsView',
  //PACHIDROP
  PACHIDROP_TRIGGER = 'pachiDropTrigger',
  PACHIDROP_FS_TRIGGER = 'pachiDropFSTrigger',
  PACHIDROP_ZOOM_START = 'pachiDropZoomStart',
  PACHIDROP_DROP_START = 'pachiDropDropStart',
  PACHIDROP_WIN_START = 'pachiDropWinStart',
  PACHIDROP_DROP_END = 'pachiDropDropEnd',
  PACHIDROP_END = 'pachiDropEnd',
  PACHIDROP_PRIZE_ANIM_START = 'pachiDropPrizeAnimStart',
  PACHIDROP_NEXT_DROP = 'pachiDropNextDrop',
  PACHIDROP_WIN_COUNT_END = 'pachiDropWinCountEnd',
  PACHIDROP_SHOW_WIN_COUNT = 'pachiDropShowWinCount',
  PACHIDROP_WHEEL_WIN_COUNT_END = 'pachiDropWheelWinCountEnd',
  PACHIDROP_SHOW_WHEEL = 'pachiDropShowWheel',
  PACHIDROP_WHEEL_ADVENT = 'pachiDropWheelAdvent',
  PACHIDROP_WHEEL_SHOW_BTN = 'pachiDropWheelShowBtn',
  PACHIDROP_WHEEL_BTN_PUSH = 'pachiDropWheelBtnPush',
  PACHIDROP_WHEEL_SPIN_START = 'pachiDropWheelSpinStart',
  PACHIDROP_WHEEL_SPIN_STOP = 'pachiDropWheelSpinSrop',
  PACHIDROP_WHEEL_ALL_END = 'pachiDropWheelAllEnd',
  PACHIDROP_DROP_COIN_START = 'pachiDropDropCoinStart',
  PACHIDROP_DROP_COIN_TRACE = 'pachiDropDropCoinTrace',
  PACHIDROP_WHEEL_COIN_IN_EFFECT = 'pachiDropWheelCoinInEffect',
  PACHIDROP_COIN_IN_ALTER = 'pachiDropCoinInAlter',
  PACHIDROP_SET_COIN_CNT_VISIBLE = 'pachiDropSetCoinCntVisible',
  PACHIDROP_SET_COIN_CNT = 'pachiDropSetCoinCnt',
  PACHIDROP_SET_FREESPIN_CNT_VISIBLE = 'pachiDropSetFreeSpinCntVisible',
  PACHIDROP_SET_FREESPIN_CNT = 'pachiDropSetFreeSpinCnt',
  PACHIDROP_UPD_FREESPIN_CNT = 'pachiDropUpdFreeSpinCnt',
  PACHIDROP_SETUP_PRIZES = 'pachiDropSetUpPrizes',
  PACHIDROP_SET_WHEEL_VIEW = 'pachiDropSetWheelView',
  INITIALIZE_COIN_TO_TRACE = 'InitializeCoinToTrace',
  BROKEN_GAME_PACHIDROP_SHOW_WHEEL = 'brokenGamePachiDropShowWheel',
  SET_REPLAY_BET_ID = 'setReplayBetId',

  // frb
  SET_IS_FREE_ROUND_BONUS = 'setIsFreeRoundBonus',
  SET_IS_POPUP_FREE_ROUNDS_OPENED = 'isOpenPopupFreeRounds',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE = 'updateFreeRoundBonusTotalWinValue',
  OPEN_POPUP_FREE_ROUNDS = 'openPopupFreeRounds',
  OPEN_POPUP_FREE_ROUNDS_END = 'openPopupFreeRoundsEnd',
  START_FADE_TRANSITION_FREE_ROUNDS_BONUS = 'startFadeTransitionFreeRoundBonus',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoPlay',
  FREE_ROUND_BONUS_EXPIRE_CHECK = 'freeRoundBonusExpireCheck',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: () => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageFreeSpinsBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HIDE_WIN_LINES]: (line: IWinLine[]) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number, stopSoundSymbolNo: number) => void;
  [EventTypes.REEL_SC_STOPPED]: (reelId: number, stopSoundSymbolNo: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_WIN_LABEL_TEXT]: () => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (nextResult: ISettledBet, isTurboSpin: boolean) => void;
  [EventTypes.START_SPIN_ANIMATION]: (isTurboSpin?: boolean) => void;
  [EventTypes.START_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  [EventTypes.START_FADE]: (fadeOutDuration: number, fadeInDuration: number, tint?: number) => void;
  [EventTypes.START_FS_WIN_ANIMATION]: () => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], reelId?: number) => void;
  [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MINOR_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MAJOR_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GRAND_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterNo: number[],
    anticipationStartReelId: number,
    scatterAnnounceType: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.SET_IS_LEFT_HAND_MODE]: (isLeftHandMode: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREESPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.HANDLE_DESTROY_OPENING_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: (isError: boolean) => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  // BuyFeature
  [EventTypes.START_BUY_FEATURE_ROUND]: (id: string) => void;
  [EventTypes.BUY_FEATURE_CONFIRMED]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.SET_UI_BUTTON_VISIBLE]: (visble: boolean) => void;
  [EventTypes.END_FREESPINS]: () => void;
  [EventTypes.FREESPINS_PACHIDROP_DROP_END]: () => void;
  [EventTypes.SET_FREESPINS_VIEW]: () => void;

  //PachiDrop
  [EventTypes.PACHIDROP_TRIGGER]: () => void;
  [EventTypes.PACHIDROP_FS_TRIGGER]: () => void;
  [EventTypes.PACHIDROP_DROP_START]: () => void;
  [EventTypes.PACHIDROP_DROP_END]: () => void;
  [EventTypes.PACHIDROP_END]: () => void;
  [EventTypes.PACHIDROP_ZOOM_START]: (isStart: boolean) => void;
  [EventTypes.PACHIDROP_PRIZE_ANIM_START]: () => void;
  [EventTypes.PACHIDROP_NEXT_DROP]: () => void;
  [EventTypes.PACHIDROP_WIN_START]: (winAmount: number, winType: WinType) => void;
  [EventTypes.PACHIDROP_SHOW_WIN_COUNT]: () => void;
  [EventTypes.PACHIDROP_WIN_COUNT_END]: () => void;
  [EventTypes.PACHIDROP_SHOW_WHEEL]: () => void;
  [EventTypes.PACHIDROP_WHEEL_ADVENT]: () => void;
  [EventTypes.PACHIDROP_WHEEL_SHOW_BTN]: () => void;
  [EventTypes.PACHIDROP_WHEEL_BTN_PUSH]: () => void;
  [EventTypes.PACHIDROP_WHEEL_SPIN_START]: () => void;
  [EventTypes.PACHIDROP_WHEEL_SPIN_STOP]: () => void;
  [EventTypes.PACHIDROP_WHEEL_WIN_COUNT_END]: () => void;
  [EventTypes.PACHIDROP_WHEEL_ALL_END]: () => void;
  [EventTypes.PACHIDROP_DROP_COIN_START]: (reelIndex: number) => void;
  [EventTypes.PACHIDROP_DROP_COIN_TRACE]: (reelIndex: number) => void;
  [EventTypes.PACHIDROP_WHEEL_COIN_IN_EFFECT]: (coinType: PachiDropCoinType) => void;
  [EventTypes.PACHIDROP_COIN_IN_ALTER]: (coinType: PachiDropCoinType) => void;
  [EventTypes.PACHIDROP_SET_COIN_CNT_VISIBLE]: (flg: boolean) => void;
  [EventTypes.PACHIDROP_SET_COIN_CNT]: (sc: number, gc: number) => void;
  [EventTypes.PACHIDROP_SET_FREESPIN_CNT_VISIBLE]: (flg: boolean) => void;
  [EventTypes.PACHIDROP_SET_FREESPIN_CNT]: (cnt: number) => void;
  [EventTypes.PACHIDROP_UPD_FREESPIN_CNT]: (cnt: number) => void;
  [EventTypes.PACHIDROP_SETUP_PRIZES]: () => void;
  [EventTypes.PACHIDROP_SET_WHEEL_VIEW]: () => void;
  [EventTypes.INITIALIZE_COIN_TO_TRACE]: (reelIndex: number) => void;
  [EventTypes.BROKEN_GAME_PACHIDROP_SHOW_WHEEL]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;

  [EventTypes.SET_IS_FREE_ROUND_BONUS]: (isFreeRoundBonus: boolean) => void;
  [EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED]: (isPopupFreeRoundsOpened: boolean) => void;
  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (rounds: number) => void;
  [EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS]: (rounds: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS_END]: (isExpired?: boolean) => void;
  [EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS]: (settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;
  [EventTypes.FREE_ROUND_BONUS_EXPIRE_CHECK]: () => void;
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

export enum BgmSoundTypes {
  BASE = 'regular',
  FS = 'fs',
  PACHIDROP = 'pachidrop',
}

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};
